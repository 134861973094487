import './Admin.css';
import React from 'react';
import {
    adminRoutes,
    videoRoutes,
    userRoutes,
    globalRoutes,
    legoinRoutes,
    activeCodeRoutes,
    agentRoutes,
    advertRoutes
} from './route';
import { Switch, Route, Redirect } from 'react-router-dom';
import Frame from './components/layout/Frame/Index';
import { isLogined } from './services/auth';

function Admin(props) {
    return isLogined() ? (
        <Frame>
            <Switch>
                {[
                    ...adminRoutes,
                    ...videoRoutes,
                    ...userRoutes,
                    ...globalRoutes,
                    ...legoinRoutes,
                    ...activeCodeRoutes,
                    ...agentRoutes,
                    ...advertRoutes
                ].map((route, key) => (
                    <Route
                        key={key}
                        path={route.path}
                        exact={route.exact}
                        render={(routeArgs) => <route.componet {...routeArgs} />}
                    />
                ))}
                <Redirect to="/admin/404" />
            </Switch>
        </Frame>
    ) : (
        <Redirect to="/login" />
    );
}

export default Admin;
