import { message } from 'antd';
import httpServer from '../utils/http-server';
// =================  请求api部分 ==============
const mainApiUrl = '/user';

// ==================   action =================
export const getListData = () => (dispatch) => {
    httpServer
        .get(mainApiUrl)
        .then((res) => {
            if (res.errcode === 0 && Array.isArray(res.data)) {
                dispatch({
                    type: actionType.LOAD,
                    list: res.data
                });
            } else {
                message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
            }
        });
};

// 更新数据
export const updateData = (id, data) => (dispatch) => {
    httpServer.post(mainApiUrl, { id, ...(data || {}) }).then((res) => {
        if (res.errcode === 0) {
            dispatch({
                type: actionType.MODIFY,
                data: { ...data, id }
            });
            message.success('修改数据成功');
        } else {
            message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
            console.error('获取数据错误');
        }
    });
};
// 添加数据
export const addData = (data) => (dispatch) => {
    httpServer.put(mainApiUrl, data).then((res) => {
        if (res.errcode === 0 && res.data) {
            dispatch({
                type: actionType.ADD,
                data: res.data
            });
            message.success('添加数据成功');
        } else {
            message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
            console.error('获取数据错误');
        }
    });
};
// 删除数据
export const deleteData = (id) => (dispatch) => {
    httpServer.delete(mainApiUrl, { id }).then((res) => {
        if (res.errcode === 0) {
            dispatch({
                type: actionType.DELETE,
                id
            });
            message.success('删除数据成功');
        } else {
            message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
            console.error('获取数据错误');
        }
    });
};

//  ==================   reducers  ==============

const initState = {
    list: [],
    page: 1,
    total: 0
};
const actionType = {
    ADD: 'ADD',
    DELETE: 'DELETE',
    MODIFY: 'MODIFY',
    LOAD: 'LOAD'
};

export default (state = initState, action) => {
    switch (action.type) {
        case actionType.LOAD:
            return { ...state, list: [...action.list] };
        case actionType.ADD:
            return { ...state, list: [...state.list, action.data] };
        case actionType.MODIFY:
            return { ...state, list: state.list.map((it) => (it.id === action.data.id ? action.data : it)) };
        case actionType.DELETE:
            return { ...state, list: state.list.filter((it) => it.id !== action.id) };
        default:
            return state;
    }
};
