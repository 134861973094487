import React, { useEffect, useState, useCallback } from 'react'
import { Card, Table, Button, Popconfirm, message, Modal, Form, Radio, Input, Upload, Select } from 'antd'
import { PlusOutlined, LoadingOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { uploadPath } from '../../services/file'
import { connect } from 'react-redux'
import { getListData, addData, updateData, deleteData, } from '../../store/frontend';
import ProTable from '@ant-design/pro-table';
import { date2str } from '../../utils/time.utils';

function Index(props) {
    const columns = [
        {
            title: '账号',
            width: 40,
            dataIndex: 'id',
            align: 'center',
            fixed: 'left',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: '姓名',
            dataIndex: 'username',
            align: 'center',
            width: 80,
        },
        {
            title: '电话',
            dataIndex: 'phone',
            align: 'center',
            width: 80,
        },
        {
            title: '注册时间',
            dataIndex: 'time',
            align: 'center',
            width: 70,
            search: false,
            render: val => date2str(val, 'YY-MM-DD')
        },
        {
            title: '交易记录',
            width: 60,
            align: 'center',
            search: false,
            render: () => <a>查看详情</a>
        },
        {
            title: '充值记录',
            width: 60,
            align: 'center',
            search: false,
            render: () => <a>查看详情</a>
        },
        {
            title: 'vip等级',
            width: 60,
            align: 'center',
            search: false,
            dataIndex: 'level',
            sorter: (a, b) => a.dataIndex - b.dataIndex
        },
        {
            title: '成长值',
            search: false,
            dataIndex: 'grow_value',
            align: 'center',
            width: 60,
            sorter: (a, b) => a.grow_value - b.grow_value
        },
        {
            title: '魅力值',
            search: false,
            dataIndex: 'm_value',
            align: 'center',
            width: 60,
            sorter: (a, b) => a.m_value - b.m_value
        },
        {
            title: '操作',
            width: 80,
            align: 'center',
            search: false,
            fixed: 'right',
            render: (val, row, index) => (
                <React.Fragment>
                    <Button
                        size="small"
                        type="link"
                        onClick={() => editItem(row)}
                    >
                        修改
                    </Button>
                    <Popconfirm
                        title="是否删除该用户？"
                        onConfirm={() => props.dispatch(deleteData(row.id))}
                    >
                        <Button
                            size="small"
                            type="link"
                            style={{ marginLeft: '1em' }}
                        >
                            禁用
                        </Button>
                    </Popconfirm>
                </React.Fragment>
            ),
        },
    ]
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [useCarSeriesId, setUseCarSeriesId] = useState(null);

    useEffect(() => {
        load()
    }, [props.match.params])
    //加载数据
    const load = () => {
        const params = props.match.params
        let condition = {}
        if (typeof params === 'object' && params !== null) {
            if (params.id) {
                condition = { car_series_id: parseInt(params.id) }
                setUseCarSeriesId(condition.car_series_id);
            }
        }
        props.dispatch(getListData(condition))
    }

    //创建表单
    const [form] = Form.useForm()

    //编辑框状态
    const [editModeStatus, setEditModeStatus] = useState(false)
    //是否添加模式
    const [isAddModel, setIsAddModel] = useState(true)
    //表单内容
    const [formContent, setFormContent] = useState({})

    //添加
    const addItem = () => {
        form.resetFields();
        form.setFieldsValue({
            car_series_id: useCarSeriesId
        });
        setFormContent({});
        setIsAddModel(true)
        setEditModeStatus(true)
    }
    //编辑
    const editItem = (row) => {
        form.setFieldsValue(row)
        setFormContent(row)
        setIsAddModel(false)
        setEditModeStatus(true)
    }

    //监听编辑框内容变化
    useEffect(() => {
        setImageUrl(formContent['thumb_show'])
    }, [formContent]);

    // 选中（复选框）
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    return (
        <React.Fragment>
            <ProTable
                headerTitle="前端用户管理"
                columns={columns}
                dataSource={props.list}
                scroll={{ x: 1000 }}
                rowKey="id"
                pagination={{
                    pageSize: 10
                }}
                editable={{
                    type: "multiple",
                    onSave: (id, row) => {
                        props.dispatch(updateData(id, row.descript));
                    },
                    actionRender: (row, config, dom) => [dom.save, dom.cancel]
                }}
                rowSelection={{
                    columnWidth: 30,
                    selectedRowKeys,
                    onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys)
                }}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false
                }}
                toolbar={{
                    actions: [
                        <Button
                            type="primary"
                            onClick={() => {
                                console.log('上传=>');
                            }}
                        >
                            添加用户
                      </Button>,
                        <Popconfirm
                            title="是否删除选中用户吗？"
                            onConfirm={() => props.dispatch(deleteData(selectedRowKeys))}>
                            <Button>删除</Button>
                        </Popconfirm>
                    ]
                }}
            ></ProTable>
            <Modal
                title={(isAddModel ? '新增' : '修改') + '用户'}
                visible={editModeStatus}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            if (values) {
                                if (
                                    typeof formContent === 'object' &&
                                    formContent !== null
                                ) {
                                    const data = { ...formContent, ...values }
                                    if (data.id) {
                                        props.dispatch(
                                            updateData(data.id, data),
                                        )
                                        setTimeout(load, 500)
                                    } else {
                                        props.dispatch(addData(data))
                                    }
                                    setEditModeStatus(false)
                                } else {
                                    message.info('系统错误').then(() => {
                                        console.error(
                                            'formContent必须是一个对象',
                                            formContent,
                                        )
                                    })
                                }
                            } else {
                                message.error('系统错误，请刷新重试！！！')
                            }
                        })
                        .catch((err) => {
                            const { errorFields } = err || {}
                            if (
                                Array.isArray(errorFields) &&
                                errorFields.length > 0
                            ) {
                                message.error(
                                    errorFields
                                        .map((it) => it.errors.join('，'))
                                        .join('，'),
                                )
                            } else {
                                console.error('无法识别的错误=>', err)
                            }
                        })
                }}
                onCancel={() => setEditModeStatus(false)}
            >
                <Form
                    labelCol={{ span: 4 }}
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    initialValues={{ is_show: 1, sort: 9999 }}
                >
                    <Form.Item
                        name="car_series_id"
                        label="所属车系"
                        rules={[{ required: true, message: '车系是必选项' }]}
                    >
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="品牌名字"
                        rules={[
                            { required: true, message: '品牌名字是必填项' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="品牌描述">
                        <Input type="textarea" />
                    </Form.Item>
                    <Form.Item name="sort" label="排序">
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="is_show"
                        className="collection-create-form_last-form-item"
                    >
                        <Radio.Group>
                            <Radio value={1}>上架</Radio>
                            <Radio value={0}>下架</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="缩略图">
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="file-uploader"
                            showUploadList={false}
                            data={{ path: 'car-info' }}
                            action={uploadPath}
                            withCredentials={true}
                            beforeUpload={(file) => {
                                const isJpgOrPng =
                                    file.type === 'image/jpeg' ||
                                    file.type === 'image/png'
                                if (!isJpgOrPng) {
                                    message.error('只能上传JPG/PNG格式图片')
                                }
                                const isLt2M = file.size / 1024 / 1024 < 2
                                if (!isLt2M) {
                                    message.error('图片大小必须小于2MB!')
                                }
                                return isJpgOrPng && isLt2M
                            }}
                            onChange={(e) => {
                                switch (e.file.status) {
                                    case 'uploading':
                                        setLoading(true)
                                        break
                                    case 'done':
                                        const res = e.file.response
                                        setLoading(false)
                                        if (res.errcode === 0) {
                                            if (res.data && res.data.file) {
                                                const file = res.data.file
                                                return setFormContent({
                                                    thumb: file.source_url,
                                                    thumb_show: file.url,
                                                    resource_id:
                                                        file.resource_id,
                                                })
                                            }
                                            message.error(
                                                '系统错误，请联系管理员',
                                            )
                                        } else {
                                            message.error(res.msg)
                                        }
                                        break
                                    case 'error':
                                        setLoading(false)
                                        message.error('文件上传错误')
                                        break
                                    default:
                                        console.log('状态=>', e.file.status, e)
                                }
                            }}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{ width: '100%' }}
                                />
                            ) : (
                                    <div>
                                        {loading ? (
                                            <LoadingOutlined />
                                        ) : (
                                                <PlusOutlined />
                                            )}
                                        <div style={{ marginTop: 8 }}>上传</div>
                                    </div>
                                )}
                        </Upload>
                    </Form.Item>
                </Form>

            </Modal>
        </React.Fragment>
    )
}

export default connect((state) => state.frontend)(withRouter(Index))
