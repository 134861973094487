//参数正则
const argRe = /\:[a-zA-Z_$][a-zA-Z_0-9$]*(\?{0,1})/g;
//去掉右边的/
const rtrim = function (str) {
    if (typeof str === 'string') {
        return str.replace(/^(.*?)\/$/, '$1');
    } else {
        return str;
    }
};

//去掉路由中参数匹配
export const removeRouteArgsDefine = (routeIt) => {
    if (typeof routeIt === 'object' && routeIt !== null) {
        const it = { ...routeIt };
        it.path = rtrim(it.path.replace(argRe, ''));
        return it;
    }
    return routeIt;
};

// 去掉参数
export const removeRouteArgs = (allRoutes, path) => {
    if (Array.isArray(allRoutes) && allRoutes.length > 0) {
        for (const it of allRoutes) {
            if (typeof it === 'object' && it !== null) {
                let rp = it.path;
                if (typeof rp === 'string' && rp !== '') {
                    try {
                        let rpReStr = rp.replace(argRe, (_, $1) => `.*${$1}`);
                        if (new RegExp(`^${rpReStr}$`).test(path)) {
                            return rtrim(rp.replace(argRe, ''));
                        }
                    } catch (e) {}
                }
            }
        }
        return path;
    } else {
        return path;
    }
};
