import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import '@ant-design/pro-table/dist/table.css';
import Admin from './Admin';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { systemRoutes } from './route';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/admin" render={(routeArgs) => <Admin {...routeArgs} />} />
                    {systemRoutes.map((route, key) => (
                        <Route key={key} {...route} />
                    ))}
                    <Redirect to="/admin" from="/" />
                    <Redirect to="/404" />
                </Switch>
            </Router>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);
