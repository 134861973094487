import httpServer from '../utils/http-server';
import { setUser } from '../services/auth';
import { setSetting } from '../services/setting';

const api = {
    login: 'user/login',
    logout: 'user/logout'
};
export const login = (username, password) => {
    return new Promise((resolve, reject) => {
        if (username && password) {
            httpServer
                .post(api.login, {
                    phone: username,
                    password
                })
                .then((res) => {
                    if (res.errcode === 0 && res.data) {
                        const data = res.data;
                        if (typeof data === 'object' && data !== null && data.user) {
                            setUser(data.user);
                            setSetting(data.setting);
                            return resolve();
                        }
                    }
                    reject(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
                });
        } else {
            reject('用户名或者密码错误');
        }
    });
};

export const logout = () => {
    return new Promise((resolve) => {
        setUser(null);
        httpServer.delete(api.logout).then(resolve).catch(resolve);
    });
};
