/*
 * @Description: 配置方面
 * @Author: wkj
 * @Date: 2021-01-22 15:17:05
 * @,@LastEditTime: ,: 2021-01-25 11:24:32
 * @,@LastEditors: ,: wkj
 */
const saveSettingKey = 'setting';
export const setSetting = function (setting) {
    sessionStorage.setItem(saveSettingKey, JSON.stringify(setting));
};

export const getSetting = function () {
    let val = sessionStorage.getItem(saveSettingKey);
    if (val) {
        try {
            return JSON.parse(val);
        } catch (error) {
            return {};
        }
    } else {
        return {};
    }
}

// 获取设置中子项
const getSettingItem = function (name, filterCb) {
    const setting = getSetting();
    const filter = typeof filterCb === 'function' ? filterCb : (it) => it;
    if (typeof setting === 'object' && setting !== null) {
        return filterCb(setting[name]);
    } else {
        return filterCb(null);
    }

}

// 视频视频配置列表
const _getSettingItemList = function (itemName) {
    return getSettingItem(itemName, val => {
        if (typeof val === 'object' && val !== null) {
            let valList = [];
            for (const key in val) {
                const it = val[key];
                if (Array.isArray(it) && it.length === 2) {
                    valList.push({
                        name: key,
                        value: it[0],
                        text: it[1]
                    });
                }
            }
            return valList;
        } else {
            return [];
        }
    });
};
const _getSettingItemListDict = function () {
    return getSettingItem(itemName, val => {
        if (typeof val === 'object' && val !== null) {
            for (const key in val) {
                const it = val[key];
                if (Array.isArray(it) && it.length === 2) {
                    valDict[it[0]] = {
                        name: key,
                        value: it[0],
                        text: it[1]
                    };
                }
            }
            return val;
        } else {
            return {};
        }
    });
}

// 视频视频配置列表
export const _getSettingItemDict = function (itemName) {
    return getSettingItem(itemName, val => {
        if (typeof val === 'object' && val !== null) {
            let valDict = {};
            for (const key in val) {
                const it = val[key];
                if (Array.isArray(it) && it.length === 2) {
                    valDict[it[0]] = it[1];
                }
            }
            return valDict;
        } else {
            return [];
        }
    });
};

// 视频设置
export const getVideoStatusList = () => _getSettingItemList('video');
export const getVideoStatusDict = () => _getSettingItemDict('video');

// 军团联盟设置
export const getLegoinStatusList = () => _getSettingItemList('legoin');
export const getLegoinStatusDict = () => _getSettingItemDict('legoin');
