// 动态获取资源与API接口host
const { devHost, prefix } = require('./env');
export { prefix };
export const { resourceHost, apiHost, useProxy } = (function (h, p) {
    const isProd = /^https/.test(p);
    const host = isProd ? p + '//' + h : devHost;
    return {
        resourceHost: host,
        apiHost: host,
        useProxy: !isProd
    };
})(window.location.host, window.location.protocol);
