import React, { useEffect, useState,useRef } from 'react';
import { Button, Modal, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProTable from '@ant-design/pro-table';
import { Select, Form } from 'antd';
import { getListData, getListData2,addData } from '../../store/active-code';
import dayjs from 'dayjs';
import { isNum } from '../../utils/types.utils';
const { Paragraph } = Typography;
const { Option } = Select;

function Index(props) {
    const [form] = Form.useForm();
    const activeCodeType = [
        {
            type: 1,
            text: '月付费时长会员'
        },
        {
            type: 2,
            text: '季付费时长会员'
        },
        {
            type: 3,
            text: '年付费时长会员'
        },
        {
            type: 4,
            text: '教官'
        },
        {
            type: 5,
            text: '指挥官'
        },
        {
            type: 6,
            text: '大统领'
        },
        // {
        //     type: 34,
        //     text: '三天付费会员'
        // },
        {
            type: 35,
            text: '七天付费会员'
        },
        {
            type: 36,
            text: '15天付费会员'
        },
        {
            type: 37,
            text: '60天付费会员'
        }
    ];
    const activeCodeSelect = {
        1: '月付费时长会员',
        2: '季付费时长会员',
        3: '年付费时长会员',
        4: '教官',
        5: '指挥官',
        6: '大统领',
        34:'三天付费会员',
        35: '七天付费会员',
        36:'15天付费会员',
        37: '60天付费会员'
    };
    const getActiveText2Type = (type) => {
        const findItem = activeCodeType.find((item) => item.type === type);
        if (findItem) {
            return findItem['text'];
        }
        return '未识别的激活码类型';
    };
    const columns = [
        {
            title: '激活码',
            width: 100,
            dataIndex: 'cdkey',
            align: 'left',
            fixed: 'left',
            render: (cdkey) => {
                return (
                    <span style={{ cursor: 'pointer', paddingLeft: '10px' }}>
                        <Paragraph copyable>{cdkey}</Paragraph>
                    </span>
                );
            }
        },
        {
            title: '类型',
            width: 100,
            dataIndex: 'type',
            align: 'left',
            fixed: 'left',
            valueEnum : activeCodeSelect,
            valueType: 'select'
        },
        {
            title: '使用者',
            dataIndex: ['user', 'name'],
            key:'userName',
            align: 'left',
            editable: false,
            width: 60
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'left',
            width: 100,
            valueType: 'dateTime',
            hideInSearch: true,
            sorter: true
            // render: (createTime) => {
            //     return dayjs(new Date(createTime)).format('YYYY-MM-DD HH:mm:ss');
            // }
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'left',
            width: 100,
            valueEnum: {
                1: '未使用',
                0: '已使用'
            },
            hideInSearch: true,
            filters: true
            // render: (status) => {
            //     return <span>{status ? '未使用' : '已使用'}</span>;
            // }
        },{
            title: '使用时间',
            dataIndex: 'useTime',
            align: 'left',
            width: 100,
            valueType: 'dateTime',
            hideInSearch: true
            // render: (useTime) => {
            //     if(isNum(useTime)){
            //         return dayjs(new Date(useTime)).format('YYYY-MM-DD HH:mm:ss');
            //     }else{
            //         return ''
            //     }
                
            // }
        }
    ];
    // useEffect(() => {
    //     load();
    // }, []);
    // //加载数据
    // const load = () => {
    //     let condition = { pageSize: 3000 };
    //     props.dispatch(getListData(condition));
    // };
    // 创建激活码
    const createCode = () => {
        form.validateFields().then(() => {
            const formValue = form.getFieldsValue();
            const { type } = formValue;
            setConfirmLoading(true);
            addData({ type })
                .then(() => {
                    actionRef.current.reload();
                    setCodeVisible(false);
                    form.resetFields();
                })
                .finally(() => {
                    setConfirmLoading(false);
                });
        });
    };
    // 创建激活码modal
    const [codeVisible, setCodeVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
     //过滤数据处理
     const handlefilter = (filters) => {
        const myfilter = filters
    
         const realFliter = filters
        Object.keys(realFliter).forEach((key) => {
            if (realFliter[key] == null || realFliter[key].length == 0) {
                delete realFliter[key]
            }
        })
        return realFliter
    }
    // 排序
    const handerSorter = (sorter) => {
        console.log(sorter)
        Object.keys(sorter).forEach((key) => {
            sorter[key]=sorter[key]=== 'ascend' ? 'ASC' : 'DESC'
        })
        return sorter;
    }
    const actionRef = useRef();
    return (
        <>
            <ProTable
            actionRef={actionRef}
                headerTitle="激活码管理"
                columns={columns}
                // dataSource={props.list}
                scroll={{ x: 1000 }}
                rowKey="id"
                pagination={{
                    pageSize: 10
                }}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false
                }}
                toolbar={{
                    actions: [
                        <Button type="primary" onClick={() => setCodeVisible(true)}>
                            创建激活码
                        </Button>
                    ]
                }}
                request={ async(params, sorter, filter) => {
                    const mysort=handerSorter(sorter)
                    const myfilter=handlefilter(filter)
                    return  await  getListData2({...params,...mysort,...myfilter});
                  }}
            ></ProTable>
            <Modal
                title="创建激活码"
                visible={codeVisible}
                onOk={() => createCode()}
                confirmLoading={confirmLoading}
                onCancel={() => setCodeVisible(false)}
            >
                <>
                    <Form form={form}>
                        <Form.Item
                            label="选择激活码类型"
                            name="type"
                            rules={[{ required: true, message: '请选择激活码类型' }]}
                        >
                            <Select
                                allowClear
                                style={{ width: 200 }}
                                placeholder="选择激活码类型"
                                optionFilterProp="children"
                            >
                                {activeCodeType.map((item) => (
                                    <Option key={item.text} value={item.type}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </>
    );
}

export default connect((state) => state.legoin)(withRouter(Index));
