import React from 'react';
import { Form, Input, Button, Checkbox, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { login } from '../../services/user';
import { withRouter } from 'react-router-dom';

function Login(props) {
    //创建表单
    const [form] = Form.useForm();

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div style={{ width: 320, height: 240, marginTop: -100 }}>
                <Card title="后台管理-股播">
                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={(e) => {
                            login(e.username, e.password)
                                .then(() => {
                                    message.success('登录成功', 1);
                                    setTimeout(() => props.history.push('/'), 200);
                                })
                                .catch((msg) => {
                                    message.error(msg);
                                });
                        }}
                    >
                        <Form.Item name="username" rules={[{ required: true, message: '请输入用户名' }]}>
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: '请输入登录的密码' }]}>
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="密码"
                            />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
}

export default withRouter(Login);
