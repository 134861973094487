/*
 * @Description: 工具类：url相关的工具
 * @Author: wkj
 * @Date: 2019-11-04 13:18:05
 * @,@LastEditTime: ,: 2020-11-19 16:49:57
 * @,@LastEditors: ,: wkj
 */
//请求字符串转换对象
const requestStr2Obj = function (requestUrl) {
    // 如果存在?，那么先获取?之后的内容
    requestUrl = requestUrl.replace(/^.*?\?(.*?)$/, '$1');
    requestUrl = decodeURIComponent(requestUrl);
    var requestArr = requestUrl.split('&');
    var valueRe = /^[a-zA-Z0-9\_\_]+=[^=]*?$/;
    var paramsObj = {};
    //查看类型进行转为相应的值
    const val2Real = (val) => {
        let intRe = /^\d+$/,
            floatRe = /^\d*\.\d*$/,
            trueRe = /^true$/i,
            falseRe = /^false$/i;
        if (falseRe.test(val)) {
            return false;
        } else if (trueRe.test(val)) {
            return true;
        } else if (floatRe.test(val)) {
            return parseFloat(val);
        } else if (intRe.test(val)) {
            return parseInt(val);
        } else {
            return val;
        }
    };
    for (var i = 0, it = null; i < requestArr.length; i++) {
        it = requestArr[i];
        if (valueRe.test(it)) {
            var vArr = it.split('=');
            paramsObj[vArr[0]] = val2Real(vArr[1]);
        }
    }
    return paramsObj;
};

//对象转url参数
const obj2RequestUrl = function (obj) {
    if (typeof obj === 'object' && obj !== null) {
        //去掉function
        var newObj = JSON.parse(JSON.stringify(obj));
        var tempRequestArr = [];
        for (var i in newObj) {
            var val = newObj[i];
            if (typeof i !== 'string' || i === '') {
                continue;
            } else {
                var key = i;
                var attrStr = '';
                if (Array.isArray(val)) {
                    if (!/\[\]$/.test(key)) {
                        key += '[]';
                    }
                    var valAttr = [];
                    for (var i in val) {
                        if (typeof val[i] === 'object') {
                            console.error('只是支持一级数组！！！');
                            continue;
                        } else {
                            valAttr.push(key + '=' + encodeURIComponent(val[i]));
                        }
                    }
                    attrStr = valAttr.join('&');
                } else if (typeof val === 'object') {
                    attrStr = key + '=' + encodeURIComponent(JSON.stringify(val));
                } else {
                    attrStr = key + '=' + encodeURIComponent(val);
                }
                tempRequestArr.push(attrStr);
            }
        }
        return tempRequestArr.join('&');
    } else {
        console.error('无法转换，使用Object不合法！！！');
        throw Error('无法转换，使用Object不合法！！！');
    }
};

//URL合并
const mergeUrl = function (mainUrl) {
    if (typeof mainUrl !== 'string') {
        throw new Error('main url main be a string!');
    }
    var allRequest = {};
    for (var i = 1; i < arguments.length; i++) {
        var item = arguments[i];
        if (typeof item === 'object') {
            Object.assign(allRequest, item);
        } else if (typeof item === 'string') {
            Object.assign(allRequest, requestStr2Obj(item));
        }
    }
    if (typeof allRequest === 'object' && allRequest !== null) {
        var connector = '?';
        if (/\?/.test(mainUrl)) {
            connector = '&';
        }
        mainUrl += connector + obj2RequestUrl(allRequest);
    }
    return mainUrl;
};

module.exports = {
    requestStr2Obj,
    obj2RequestUrl,
    mergeUrl
};
