import {
    CompassOutlined,
    MacCommandOutlined,
    UnorderedListOutlined,
    SettingOutlined,
    ApartmentOutlined,
    FlagOutlined
} from '@ant-design/icons';
import React from 'react';
import Login from '../pages/System/Login';
import PageNotFound from '../pages/System/PageNotFound';
import AdminPageNotFound from '../pages/System/AdminPageNotFound';
import VideoList from '../pages/Video/List';
import LegoinList from '../pages/Legoin/List';
import AgentList from '../pages/Agent/List';
import AdminUser from '../pages/User/Admin';
import FontendUser from '../pages/User/Frontend';
import ActiveCode from '../pages/ActiveCode/ActiveCode';
import Dashboard from '../pages/System/Dashboard';
import Setting from '../pages/System/Setting';
import AdvertList from '../pages/Advert/List'

// 系统路由
export const systemRoutes = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/404',
        component: PageNotFound
    }
];

// 管理后台
export const adminRoutes = [
    {
        path: '/admin',
        componet: Dashboard,
        exact: true
    },
    {
        path: '/admin/404',
        componet: AdminPageNotFound
    }
];

// 资源管理路由
export const userRoutes = [
    {
        path: '/admin/user/frontend/:id?',
        componet: FontendUser,
        exact: true,
        isShow: true,
        title: '前端用户',
        icon: <CompassOutlined />
    },
    {
        path: '/admin/user/admin/:id?',
        componet: AdminUser,
        exact: true,
        isShow: true,
        title: '后端用户',
        icon: <MacCommandOutlined />
    }
];

// 激活码管理路由
export const activeCodeRoutes = [
    {
        path: '/admin/acticeCode',
        componet: ActiveCode,
        exact: true,
        isShow: true,
        title: '激活码列表'
    }
];

// 全局设置
export const globalRoutes = [
    {
        path: '/admin/setting',
        componet: Setting,
        title: '设置',
        exact: true,
        isShow: true,
        icon: <SettingOutlined />
    }
];

export const videoRoutes = [
    {
        path: '/admin/video/list/:id?',
        componet: VideoList,
        isShow: true,
        title: '视频列表',
        icon: <UnorderedListOutlined />
    }
];

export const legoinRoutes = [
    {
        path: '/admin/legoin/list/:id?',
        componet: LegoinList,
        isShow: true,
        title: '军团联盟',
        icon: <ApartmentOutlined />
    }
];

export const agentRoutes = [
    {
        path: '/admin/agent/list/:id?',
        componet: AgentList,
        isShow: true,
        title: '代理商列表',
        icon: <UnorderedListOutlined />
    }
];

export const advertRoutes = [
    {
        path: '/admin/advert/list/:id?',
        componet: AdvertList,
        isShow: true,
        title: '广告平台列表',
        icon: <FlagOutlined />
    }
];

