import { message } from 'antd';
import httpServer from '../utils/http-server';
// =================  请求api部分 ==============
const apiList = {
    list: '/cdkey/list',
    create: '/cdkey/createCDKey'
};

// ==================   action =================
// 列表
export const getListData = (condition) => (dispatch) => {
    httpServer.get(apiList.list, { ...condition }).then((res) => {
        if (res.errcode === 0 && Array.isArray(res.data)) {
            dispatch({
                type: actionType.LOAD,
                list: res.data
            });
        } else {
            message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
        }
    });
};
export const getListData2 = function (params) {
    return new Promise((resolve) => {
        httpServer
            .post(apiList.list, params)
            .then((res) => {
                resolve({
                    data: res.data.data,
                    // success 请返回 true，
                    // 不然 table 会停止解析数据，即使有数据
                    success: true,
                    // 不传会使用 data 的长度，如果是分页一定要传
                    total: res.data.total
                });
            })
            .catch((err) => {
                resolve({
                    data: [],
                    // success 请返回 true，
                    // 不然 table 会停止解析数据，即使有数据
                    success: false,
                    // 不传会使用 data 的长度，如果是分页一定要传
                    total: 0
                });
            });
    });
};

// 添加数据
export const addData = (data) => {
    return new Promise((resolve, reject) => {
        httpServer
            .post(apiList.create, { ...data })
            .then((res) => {
                if (res.errcode === 0 && res.data) {
                    resolve(res.data);
                } else {
                    reject(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
                }
            })
            .catch(() => {
                message.error('添加错误');
            });
    });
};

//  ==================   reducers  ==============

const initState = {
    list: [],
    page: 1,
    total: 0
};
const actionType = {
    LOAD: 'LOAD'
};

export default (state = initState, action) => {
    switch (action.type) {
        case actionType.LOAD:
            return { ...state, list: [...action.list] };
        default:
            return state;
    }
};
