import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { uploadPath } from '../../services/file';
import PreviewImg from './PreviewImg';

// 图片上传
const ImgUpload = ({
    limit = 1,
    fileColumn = 'file',
    path = 'car-img',
    files,
    fileName = '图片',
    onUpload = null,
    onRemove = null
}) => {
    let preview = null;
    const [useFiles, setUseFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let temp = files;
        if (temp) {
            if (!Array.isArray(temp)) {
                temp = [temp];
            }
            const list = [];
            for (let i in temp) {
                let it = temp[i];
                if (typeof it === 'object' && it !== null) {
                    if (typeof it.uid !== 'undefined' && !it.url) {
                        list.push(it);
                    } else {
                        console.error('1数据错误，数据结构{url, name, uid, status: "done"}');
                    }
                } else if (typeof it === 'string' && it !== '') {
                    i = parseInt(i);
                    list.push({
                        url: it,
                        uid: i,
                        name: `${fileName}-[${i + 1}]`,
                        status: 'done'
                    });
                } else {
                    console.error('2数据错误，数据结构{url, name, uid, status: "done"}', it);
                }
            }
            setUseFiles(list);
        } else {
            setUseFiles([]);
        }
    }, [files])
    return <React.Fragment>
        <Upload name={fileColumn} listType="picture-card" data={{ path }} action={uploadPath} withCredentials={true}
            onPreview={(e) => {
                preview({
                    title: e.name,
                    url: e.url
                });
            }}
            fileList={useFiles}
            beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    message.error('只能上传JPG/PNG格式图片');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error('图片大小必须小于2MB!')
                }
                return isJpgOrPng && isLt2M
            }}
            onChange={(e) => {
                switch (e.file.status) {
                    case 'uploading':
                        setLoading(true);
                        break;
                    case 'done':
                        setLoading(false);
                        const res = e.file.response;
                        if (res.errcode === 0) {
                            if (res.data && res.data[fileColumn]) {
                                if (typeof onUpload === 'function') {
                                    onUpload(res.data[fileColumn]);
                                } else {
                                    console.error('没有回调函数onUpload', res.data[fileColumn]);
                                }
                            } else {
                                message.error('系统错误，请联系管理员');
                            }
                        } else {
                            message.error(res.msg)
                        }
                        break
                    case 'error':
                        setLoading(false)
                        message.error('文件上传错误')
                        break
                    default:
                        console.log('状态=>', e.file.status, e)
                }
            }}
            onRemove={({ uid }) => {
                if (typeof onRemove === 'function') {
                    onRemove(uid);
                }
            }}
        >
            {useFiles.length >= limit ? null : <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传图片</div>
            </div>}
        </Upload>
        <PreviewImg ready={(show) => (preview = show)} />
    </React.Fragment>
}

export default ImgUpload
