import React, { useEffect, useState, useRef } from 'react';
import { Card, Table, Button, Popconfirm, message, Modal, Form, Image, Input, AutoComplete } from 'antd';
import { withRouter } from 'react-router-dom';
import { addURLHost, fallbackUrl } from '../../services/file';
import { connect } from 'react-redux';
import { getListData2, getListData, addData, updateData, deleteData } from '../../store/legoin';
import { date2str } from '../../utils/time.utils';
import { getLegoinStatusDict, getLegoinStatusList } from '../../services/setting';
import ProTable from '@ant-design/pro-table';

function Index(props) {
    const legoinStatusDict = getLegoinStatusDict();
    const legoinStatusList = getLegoinStatusList();
    const columns = [
        {
            title: '加盟名称',
            width: 100,
            dataIndex: 'name',
            align: 'left',
            fixed: 'left'
        },
        {
            title: '用户账号',
            dataIndex: 'userId',
            align: 'left',
            editable: false,
            width: 60
        },
        {
            title: '电话号码',
            dataIndex: 'phone',
            align: 'left',
            width: 100
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            align: 'left',
            width: 100
        },
        {
            title: '加盟地区',
            dataIndex: 'province',
            align: 'left',
            width: 100,
            editable: false,
            render: (val, row) =>
                row && row.province ? `${row.province || '---'}省${row.city || '---'}${row.district || '---'}` : '-'
        },
        {
            title: '申请时间',
            dataIndex: 'createTime',
            align: 'center',
            editable: false,
            search: false,
            width: 80,
            sorter: true,
            render: (val) => date2str(val, 'YY-MM-DD')
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: 60,
            render: (_, row) => row && legoinStatusDict[row.status],
            fixed: 'right',
            filters: true,
            search: false,
            onFilter: true,
            valueType: 'select',
            editable: false,
            valueEnum: ((list) => {
                if (Array.isArray(list)) {
                    var dict = {};
                    list.forEach((it) => {
                        dict[it.value] = it.text;
                    });
                    return dict;
                } else {
                    return {};
                }
            })(legoinStatusList)
        },
        {
            title: '审核状态',
            width: 100,
            align: 'center',
            fixed: 'right',
            editable: false,
            render: (text, row, _, action) => (
                <React.Fragment>
                    {/* 发起审核 */}
                    {row && row.status === '1' && (
                        <>
                            <a
                                onClick={() => {
                                    props.dispatch(updateData(row.id, { status: '3' }));
                                    actionRef.current.reload();
                                }}
                            >
                                通过
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a
                                style={{ color: 'red' }}
                                onClick={() => {
                                    props.dispatch(updateData(row.id, { status: '2' }));
                                    actionRef.current.reload();
                                }}
                            >
                                拒绝
                            </a>
                        </>
                    )}
                    {/* 审核失败 */}
                    {/* {row && row.status === "2" && <a onClick={() => {
                    props.dispatch(updateData(row.id, { status: "3" }));
                }}>通过</a>} */}
                    {/* 发布成功 */}
                    {row && (row.status === '3' || row.status === '2') && (
                        <a
                            style={{ color: 'red' }}
                            onClick={() => {
                                props.dispatch(updateData(row.id, { status: '1' }));
                                actionRef.current.reload();
                            }}
                        >
                            撤回
                        </a>
                    )}
                </React.Fragment>
            )
        },
        {
            title: '管理',
            width: 80,
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            render: (text, record, _, action) => (
                <React.Fragment>
                    <a
                        key="editable"
                        onClick={() => {
                            action.startEditable?.(record.id);
                        }}
                    >
                        编辑
                    </a>
                    <Popconfirm
                        title="是否删除该军团联盟吗？"
                        onConfirm={() => {
                            props.dispatch(deleteData(record.id));
                            actionRef.current.reload();
                        }}
                    >
                        <a style={{ marginLeft: '1em', color: '#999' }}>删除</a>
                    </Popconfirm>
                </React.Fragment>
            )
        }
    ];

    // useEffect(() => {
    //     load()
    // }, [props.match.params]);
    //加载数据
    // const load = (paramsBak) => {
    //     if(paramsBak){
    //         const params = paramsBak;
    //     }else{
    //         const params = props.match.params
    //     }

    //     let condition = {}
    //     if (typeof params === 'object' && params !== null) {
    //         if (params.id) {
    //             condition = { car_brand_id: parseInt(params.id) }
    //         }
    //     }
    //     props.dispatch(getListData({...condition, ...params}))
    // }
    //过滤数据处理
    const handlefilter = (filters) => {
        const myfilter = filters;

        const realFliter = filters;
        Object.keys(realFliter).forEach((key) => {
            if (realFliter[key] == null || realFliter[key].length == 0) {
                delete realFliter[key];
            }
        });
        return realFliter;
    };
    // 排序
    const handerSorter = (sorter) => {
        console.log(sorter);
        Object.keys(sorter).forEach((key) => {
            sorter[key] = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
        });
        return sorter;
    };
    const dataList = [];
    const actionRef = useRef();
    return (
        <ProTable
            actionRef={actionRef}
            headerTitle="军团管理"
            columns={columns}
            scroll={{ x: 1000 }}
            rowKey="id"
            pagination={{
                pageSize: 10
            }}
            editable={{
                type: 'multiple',
                onSave: (id, row) => {
                    const { name, phone, email } = row || {};
                    props.dispatch(updateData(id, { name, phone, email }));
                },
                actionRender: (row, config, dom) => [dom.save, dom.cancel]
            }}
            options={{
                density: false,
                fullScreen: false,
                reload: false
            }}
            request={async (params, sorter, filter) => {
                const mysort = handerSorter(sorter);
                const myfilter = handlefilter(filter);
                return await getListData2({ ...params, ...mysort, ...myfilter });
            }}
        ></ProTable>
    );
}

export default connect((state) => state.legoin)(withRouter(Index));
