const saveUserInfoKey = 'login-user';
export const setUser = (userInfo) => {
    sessionStorage.setItem(saveUserInfoKey, JSON.stringify(userInfo));
};

export const getUser = () => {
    const user = sessionStorage.getItem(saveUserInfoKey);
    if (user) {
        try {
            const u = JSON.parse(user);
            if (typeof u === 'object' && u !== null && u.id) {
                return u;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
};

export function isLogined() {
    if (getUser()) {
        return true;
    } else {
        return false;
    }
}

export function setToken(token) {
    sessionStorage.setItem('token', token);
}

export function getToken(token) {
    return sessionStorage.getItem(token);
}
