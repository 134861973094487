import React, { useState, useEffect } from 'react';
import { Modal } from 'antd'

//图片预览方面
const PreviewImg = ({ title = null, url = null, ready = null }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    useEffect(() => {
        setPreviewTitle(title);
        setPreviewUrl(url);
    }, [title, url]);

    useEffect(() => {
        //把关闭与打开函数回调到外部
        if (typeof ready === 'function') {
            //ready(showFunction, hideFunction)
            ready((options) => {
                const { title, url } = options || {};
                if (typeof title !== 'undefined') {
                    setPreviewTitle(title);
                }
                if (typeof url !== 'undefined') {
                    setPreviewUrl(url);
                }
                setPreviewVisible(true);
            }, () => setPreviewVisible(false));
        }
    }, [ready]);

    return <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}>
        <img style={{ width: '100%' }} src={previewUrl} />
    </Modal>
}

export default PreviewImg
