import React, { useState, useEffect } from 'react'
import { Card, Form, Radio, Upload, Modal, Button, message, Tooltip, List, Input } from 'antd';
import { RedoOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getListData, updateData } from '../../store/global-setting';
import ImgUpload from '../../components/common/ImgUpload';

function Index(props) {
    //表单内容
    const [formContent, setFormContent] = useState({});
    const [inputTmp, setInputTmp] = useState({});
    const [formShowContent, setFormShowContent] = useState({});
    const [useShowList, setUseShowList] = useState([]);

    useEffect(() => {
        props.dispatch(getListData());
    }, []);

    useEffect(() => {
        const list = props.list;
        if (Array.isArray(list)) {
            setUseShowList(list);
        } else {
            setUseShowList([]);
        }
        setFormDataFormList(list);
    }, [props.list])
    //从列表中设置表单数据
    const setFormDataFormList = list => {
        const dict = {};
        const dict_show = {};
        if (Array.isArray(list)) {
            for (let it of list) {
                dict[it.key] = it.val;
                dict_show[it.key] = typeof it.val_show !== 'undefined' ? it.val_show : it.val;
            }
        }
        setFormContent(dict);
        setFormShowContent(dict_show);
    }

    //图片预览方面
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    //表单变化
    const onFormChange = ({ target: { name, value } }) => {
        const formData = {};
        formData[name] = value;
        setFormContent(x => ({ ...x, ...formData }));
    }
    // input变化
    const onInputChange = ({ target: { name, value } }) => {
        const formData = {};
        formData[name] = value;
        setInputTmp(x => ({ ...x, ...formData }));
    }
    //删除子项中数组中元素
    const deleteFormArrayItemArray = (key, delIndex) => {
        let val = formContent[key];
        if (Array.isArray(val) && val.length > delIndex && delIndex >= 0) {
            val = val.filter((_, i) => delIndex !== i);
            const formData = {};
            formData[key] = val;
            setFormContent(x => ({ ...x, ...formData }));
        }
        let showVal = formShowContent[key];
        if (Array.isArray(showVal) && showVal.length > delIndex && delIndex >= 0) {
            showVal = showVal.filter((_, i) => delIndex !== i);
            const formShowData = {};
            formShowData[key] = showVal;
            setFormShowContent(x => ({ ...x, ...formShowData }));
        }
    }
    //添加
    const addFormArrayItemArray = (key, valIt, showValIt) => {
        if (typeof key !== 'string' || key === null) {
            return message.error('系统错误，请刷新后重试！！！');
        }
        if (typeof showValIt !== 'undefined') {
            let showVal = formShowContent[key];
            if (!Array.isArray(showVal)) {
                showVal = [];
            }
            const formShowData = {};
            formShowData[key] = [...showVal, showValIt];
            setFormShowContent(x => ({ ...x, ...formShowData }));
        }
        if (typeof valIt !== 'undefined') {
            let val = formContent[key];
            if (!Array.isArray(val)) {
                val = [];
            }
            const formData = {};
            formData[key] = [...val, valIt];
            setFormContent(x => ({ ...x, ...formData }));
        }
    }

    return (
        <React.Fragment>
            <Card
                title={<div>
                    <span style={{ display: 'inline-block', paddingRight: 10 }}>系统配置</span>
                    <Tooltip placement="top" title="重置设置" arrowPointAtCenter>
                        <Button size="small" type="link" onClick={() => {
                            setFormDataFormList(useShowList);
                            setInputTmp({});
                            message.success('重置数据成功');
                        }}>
                            <RedoOutlined />
                        </Button>
                    </Tooltip>
                </div>}
                extra={<Button type="primary" onClick={() => {
                    if (typeof formContent === 'object' && formContent !== null) {
                        updateData(formContent);
                    } else {
                        message.info('系统错误').then(() => console.error('formContent必须是一个对象', formContent))
                    }
                }}>保存</Button>}
            >
                <Form
                    labelCol={{ span: 6 }}
                    layout="horizontal"
                >
                    {useShowList.map((it, k) => <Form.Item key={k} label={it.description} >
                        {/* 单选项 */}
                        {it.key_type === 'radio-boolean' && <React.Fragment>
                            <Radio.Group onChange={onFormChange} name={it.key} value={formContent[it.key]}>
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </React.Fragment>}
                        {/* 图片列表 */}
                        {it.key_type === 'list-img' && <ImgUpload path="setting" fileName={it.description} files={formShowContent[it.key]} limit={5}
                            onUpload={(file) => addFormArrayItemArray(it.key, file.source_url, file.url)}
                            onRemove={uid => deleteFormArrayItemArray(it.key, uid)} />}
                        {/* 文字列表 */}
                        {it.key_type === 'list-string' && <div>
                            <List
                                header={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Input name={it.key} onChange={onInputChange} value={inputTmp[it.key]} placeholder={`请输入${it.description}`} style={{ marginRight: 20 }} />
                                    <Button type="primary" size="small" onClick={() => {
                                        addFormArrayItemArray(it.key, inputTmp[it.key]);
                                        onInputChange({ target: { name: it.key, value: '' } });
                                    }}>添加</Button>
                                </div>}
                                footer={null}
                                bordered
                                style={{ width: 350 }}
                                dataSource={Array.isArray(formContent[it.key]) ? formContent[it.key] : []}
                                renderItem={(val, i) => (
                                    <List.Item>
                                        {val}<Button onClick={() => deleteFormArrayItemArray(it.key, i)} type="link" style={{ float: 'right' }}><DeleteOutlined /></Button>
                                    </List.Item>
                                )}
                            />
                        </div>}
                    </Form.Item>)}
                </Form>
            </Card>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </React.Fragment>
    )
}

export default connect(state => state.globalSetting)(withRouter(Index))
