import React, { useEffect, useState, useRef } from 'react';
import { Card, Table, Button, Popconfirm, message, Modal, Form, Image, Input, AutoComplete } from 'antd';
import { withRouter } from 'react-router-dom';
import { addURLHost, fallbackUrl } from '../../services/file';
import { connect } from 'react-redux';
import { getListData, getListData2, addData, updateData, deleteData } from '../../store/video';
import { date2str } from '../../utils/time.utils';
import { getVideoStatusDict, getVideoStatusList } from '../../services/setting';
import PreviewVideo from '../../components/common/PreviewVideo';
import ProTable from '@ant-design/pro-table';

// 预览视频
let previewVideo = () => null;

function Index(props) {
    const videoStatusDict = getVideoStatusDict();
    const videoStatusList = getVideoStatusList();
    const columns = [
        {
            title: '视频ID',
            width: 45,
            dataIndex: 'id',
            align: 'left',
            fixed: 'left',
            editable: false,
            sorter: true,
            key: 'queryId'
        },
        {
            title: '视频',
            dataIndex: 'coverUrl',
            align: 'center',
            width: 80,
            editable: false,
            search: false,
            render: (val, row) => (
                <Image
                    width={100}
                    height={134}
                    src={addURLHost(val)}
                    preview={false}
                    fallback={fallbackUrl}
                    onClick={() => {
                        previewVideo({
                            url: addURLHost(row.videoUrl)
                        });
                    }}
                />
            )
        },
        {
            title: '描述',
            dataIndex: 'descript',
            align: 'left',
            width: 80,
            search: false
        },
        {
            title: '用户名',
            dataIndex: ['user', 'name'],
            align: 'center',
            width: 50,
            key: 'username',
            ellipsis: true,
            editable: false
        },
        {
            title: '上传时间',
            dataIndex: 'uploadTime',
            align: 'center',
            editable: false,
            search: false,
            width: 120,
            sorter: true,
            render: (val) => date2str(val)
        },
        {
            title: '视频状态',
            dataIndex: 'status',
            align: 'center',
            width: 60,
            render: (_, row) => row && videoStatusDict[row.status],
            fixed: 'right',
            filters: true,
            onFilter: true,
            valueType: 'select',
            editable: false,
            search: false,
            valueEnum: ((list) => {
                if (Array.isArray(list)) {
                    var dict = {};
                    list.forEach((it) => {
                        dict[it.value] = it.text;
                    });
                    return dict;
                } else {
                    return {};
                }
            })(videoStatusList)
        },
        {
            title: '审核操作',
            width: 100,
            align: 'center',
            fixed: 'right',
            editable: false,
            search: false,
            render: (text, row, _, action) => (
                <React.Fragment>
                    {/* 草稿 */}
                    {row && (row.status === '1' || row.status === '5') && (
                        <Button
                            size="small"
                            type="danger"
                            onClick={() => {
                                props.dispatch(deleteData([row.id]));
                                actionRef.current.reload();
                            }}
                        >
                            删除
                        </Button>
                    )}
                    {/* 审核中 */}
                    {row && row.status === '2' && (
                        <>
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => {
                                    props.dispatch(updateData(row.id, { status: '3' }));
                                    actionRef.current.reload();
                                }}
                            >
                                通过审核
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                size="small"
                                type="primary"
                                onClick={() => {
                                    props.dispatch(updateData(row.id, { status: '4' }));
                                    actionRef.current.reload();
                                }}
                            >
                                拒绝
                            </Button>
                        </>
                    )}
                    {/* 已审核 */}
                    {row && (row.status === '3' || row.status === '4') && (
                        <Button
                            size="small"
                            type="danger"
                            onClick={() => {
                                props.dispatch(updateData(row.id, { status: '2' }));
                                actionRef.current.reload();
                            }}
                        >
                            撤回
                        </Button>
                    )}
                    {/* 审核失败 */}
                    {/* {row && row.status === "4" && <Button size="small" type="danger" onClick={() => {
                    props.dispatch(updateData(row.id, { status: "3" }));
                }}>通过审核</Button>} */}
                    {/* <div style={{ padding: '0.5em 0', borderTop: '1px solid #eee', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <div style={{ width: '8em' }}>
                        <AutoComplete
                            style={{ width: '100%' }}
                            options={[1, 2, 3, 4, 5].map(it => ({ value: '低俗' + it }))}
                            placeholder="不通过理由"
                            filterOption={(inputValue, option) =>
                                option && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </div>
                    <div>
                        <Button size="small" type="danger">拒绝</Button>
                    </div>
                </div> */}
                </React.Fragment>
            )
        },
        {
            title: '操作',
            width: 80,
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            render: (text, record, _, action) => (
                <React.Fragment>
                    <a
                        key="editable"
                        onClick={() => {
                            action.startEditable?.(record.id);
                        }}
                    >
                        编辑
                    </a>
                    <Popconfirm
                        title="是否删除该视频吗？"
                        onConfirm={() => {
                            props.dispatch(deleteData([record.id]));
                            actionRef.current.reload();
                        }}
                    >
                        <a style={{ marginLeft: '1em', color: '#999' }}>删除</a>
                    </Popconfirm>
                </React.Fragment>
            )
        }
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    // useEffect(() => {
    //     load()
    // }, [props.match.params]);
    // //加载数据
    // const load = () => {
    //     const params = props.match.params
    //     let condition = {}
    //     if (typeof params === 'object' && params !== null) {
    //         if (params.id) {
    //             condition = { car_brand_id: parseInt(params.id) }
    //         }
    //     }
    //     props.dispatch(getListData(condition))
    // }

    //创建表单
    const [form] = Form.useForm();

    //编辑框状态
    const [editModeStatus, setEditModeStatus] = useState(false);
    //是否添加模式
    const [isAddModel, setIsAddModel] = useState(true);
    //表单内容
    const [formContent, setFormContent] = useState({});

    //添加
    const addItem = () => {
        form.resetFields();
        form.setFieldsValue({
            car_brand_id: 0
        });
        setFormContent({});
        setIsAddModel(true);
        setEditModeStatus(true);
    };
    //编辑
    const editItem = (row) => {
        form.setFieldsValue(row);
        setFormContent(row);
        setIsAddModel(false);
        setEditModeStatus(true);
    };
    //过滤数据处理
    const handlefilter = (filters) => {
        const myfilter = filters;

        const realFliter = filters;
        Object.keys(realFliter).forEach((key) => {
            if (realFliter[key] == null || realFliter[key].length == 0) {
                delete realFliter[key];
            }
        });
        return realFliter;
    };
    // 排序
    const handerSorter = (sorter) => {
        console.log(sorter);
        Object.keys(sorter).forEach((key) => {
            if (key === 'id') {
                sorter['orderId'] = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
                delete sorter['id'];
            } else {
                sorter[key] = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
            }
        });
        return sorter;
    };
    const actionRef = useRef();
    return (
        <React.Fragment>
            <ProTable
                actionRef={actionRef}
                headerTitle="视频管理"
                columns={columns}
                scroll={{ x: 1000 }}
                rowKey="id"
                pagination={{
                    pageSize: 10
                }}
                editable={{
                    type: 'single',
                    onSave: (id, row) => {
                        props.dispatch(updateData(id, { descript: row.descript }));
                    },
                    actionRender: (row, config, dom) => [dom.save, dom.cancel]
                }}
                rowSelection={{
                    columnWidth: 30,
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys)
                }}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false
                }}
                toolbar={{
                    actions: [
                        //     <Button
                        //         type="primary"
                        //         onClick={() => {
                        //             console.log('上传视频=>');
                        //         }}
                        //     >
                        //         上传视频
                        //   </Button>,
                        <Popconfirm
                            title="是否删除选中视频吗？"
                            onConfirm={() => {
                                props.dispatch(deleteData(selectedRowKeys));
                                actionRef.current.reload();
                            }}
                        >
                            <Button>删除</Button>
                        </Popconfirm>
                    ]
                }}
                request={async (params, sorter, filter) => {
                    const mysort = handerSorter(sorter);
                    const myfilter = handlefilter(filter);
                    // const myfilter=filter
                    return await getListData2({ ...params, ...mysort, ...myfilter });
                }}
            ></ProTable>
            {/* 编辑框 */}
            <Modal
                title={(isAddModel ? '新增' : '修改') + '视频'}
                visible={editModeStatus}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            if (values) {
                                if (typeof formContent === 'object' && formContent !== null) {
                                    const data = { ...formContent, ...values };
                                    if (data.id) {
                                        props.dispatch(updateData(data.id, data));
                                        setTimeout(load, 500);
                                    } else {
                                        props.dispatch(addData(data));
                                    }
                                    setEditModeStatus(false);
                                } else {
                                    message.info('系统错误').then(() => {
                                        console.error('formContent必须是一个对象', formContent);
                                    });
                                }
                            } else {
                                message.error('系统错误，请刷新重试！！！');
                            }
                        })
                        .catch((err) => {
                            const { errorFields } = err || {};
                            if (Array.isArray(errorFields) && errorFields.length > 0) {
                                message.error(errorFields.map((it) => it.errors.join('，')).join('，'));
                            } else {
                                console.error('无法识别的错误=>', err);
                            }
                        });
                }}
                onCancel={() => setEditModeStatus(false)}
            >
                <Form
                    labelCol={{ span: 4 }}
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    initialValues={{ is_show: 1, sort: 9999 }}
                >
                    <Form.Item name="name" label="视频标题" rules={[{ required: true, message: '视频标题是必填项' }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <PreviewVideo
                ready={(preview) => {
                    previewVideo = preview;
                }}
            />
        </React.Fragment>
    );
}

export default connect((state) => state.video)(withRouter(Index));
