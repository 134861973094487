import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import video from './video';
import user from './user';
import legoin from './legoin';
import agent from './agent';
import advert from './advert';
import frontend from './frontend';
import globalSetting from './global-setting';
import AgentList from '../pages/Agent/List';

const rootRedux = combineReducers({
    video,
    frontend,
    user,
    legoin,
    agent,
    advert,
    globalSetting
});

export default createStore(rootRedux, compose(applyMiddleware(...[thunk])));
