import React, { useEffect, useState } from 'react'
import { Card, Table, Button, Popconfirm, message, Modal, Form, Radio, Input, Upload, Switch } from 'antd'
import { PlusOutlined, LoadingOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { uploadPath } from '../../services/file';
import { connect } from 'react-redux'
import { getListData, addData, updateData, deleteData } from '../../store/user';
import ProTable from '@ant-design/pro-table';
import { date2str } from '../../utils/time.utils';

function Index(props) {
    const columns = [
        {
            title: 'ID',
            width: 40,
            key: 'id',
            align: 'center',
            search: false,
            fixed: 'left',
            sorter: (a, b) => a.id - b.id,
            render: (val, row, index) => index + 1,
        },
        {
            title: '名字',
            dataIndex: 'name',
            align: 'center',
            width: 100
        },

        {
            title: '手机号',
            width: 100,
            align: 'center',
            dataIndex: 'phone'
        },
        {
            title: '状态',
            dataIndex: 'deny',
            align: 'center',
            width: 90,
            search: false,
            render: (val, row) => <Switch size="small" defaultChecked checked={val === 0} onChange={status => onStatusChange(row, status)} />,
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            fixed: 'right',
            render: (val, row, index) => (
                <React.Fragment>
                    <Button
                        size="small"
                        type="link"
                        onClick={() => editItem(row)}
                    >
                        编辑
                    </Button>
                    <Popconfirm
                        title="是否删除该管理员？"
                        onConfirm={() => props.dispatch(deleteData(row.id))}
                    >
                        <a style={{ marginLeft: '1em', color: '#aaa' }}>
                            删除
                        </a>
                    </Popconfirm>
                </React.Fragment>
            ),
        },
    ]
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)

    useEffect(() => {
        load();
    }, [props.match.params])
    //加载数据
    const load = () => {
        const params = props.match.params
        let condition = {}
        if (typeof params === 'object' && params !== null) {
            condition = { id: params.id }
        }
        props.dispatch(getListData(condition))
    }

    //创建表单
    const [form] = Form.useForm()

    //编辑框状态
    const [editModeStatus, setEditModeStatus] = useState(false)
    //是否添加模式
    const [isAddModel, setIsAddModel] = useState(true)
    //表单内容
    const [formContent, setFormContent] = useState({});

    // 禁止状态修改
    const onStatusChange = function (row, status) {
        props.dispatch(updateData(row.id, {
            deny: status ? 0 : 1
        }));
    }

    //添加
    const addItem = () => {
        form.resetFields()
        setFormContent({})
        setIsAddModel(true)
        setEditModeStatus(true)
    }
    //编辑
    const editItem = (row) => {
        form.setFieldsValue(row)
        setFormContent(row)
        setIsAddModel(false)
        setEditModeStatus(true)
    }

    //监听编辑框内容变化
    useEffect(() => {
        setImageUrl(formContent['thumb_show'])
    }, [formContent]);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    return (
        <React.Fragment>
            <ProTable
                headerTitle="后台用户管理"
                columns={columns}
                dataSource={props.list}
                scroll={{ x: 1000 }}
                rowKey="id"
                pagination={{
                    pageSize: 10
                }}
                editable={{
                    type: "multiple",
                    onSave: (videoId, row) => {
                        props.dispatch(updateData(videoId, row.descript));
                    },
                    actionRender: (row, config, dom) => [dom.save, dom.cancel]
                }}
                rowSelection={{
                    columnWidth: 30,
                    selectedRowKeys,
                    onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys)
                }}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false
                }}
                toolbar={{
                    actions: [
                        <Button
                            type="primary"
                            onClick={() => {
                                addItem
                            }}
                        >
                            添加
                      </Button>,
                        <Popconfirm
                            title="是否删除选中吗？"
                            onConfirm={() => props.dispatch(deleteData(selectedRowKeys))}>
                            <Button>删除</Button>
                        </Popconfirm>
                    ]
                }}
            ></ProTable>
            <Modal
                title={(isAddModel ? '新增' : '修改') + '管理员'}
                visible={editModeStatus}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            if (values) {
                                if (
                                    typeof formContent === 'object' &&
                                    formContent !== null
                                ) {
                                    const data = { ...formContent, ...values }
                                    if (data.id) {
                                        props.dispatch(
                                            updateData(data.id, data),
                                        )
                                    } else {
                                        props.dispatch(addData(data))
                                    }
                                    setEditModeStatus(false)
                                } else {
                                    message.info('系统错误').then(() => {
                                        console.error(
                                            'formContent必须是一个对象',
                                            formContent,
                                        )
                                    })
                                }
                            } else {
                                message.error('系统错误，请刷新重试！！！')
                            }
                        })
                        .catch((err) => {
                            const { errorFields } = err || {}
                            if (
                                Array.isArray(errorFields) &&
                                errorFields.length > 0
                            ) {
                                message.error(
                                    errorFields
                                        .map((it) => it.errors.join('，'))
                                        .join('，'),
                                )
                            } else {
                                console.error('无法识别的错误=>', err)
                            }
                        })
                }}
                onCancel={() => setEditModeStatus(false)}
            >
                <Form
                    labelCol={{ span: 4 }}
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="name"
                        label="名字"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="phone" label="手机号">
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="登录密码">
                        <Input type="password" />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default connect((state) => state.user)(withRouter(Index))
