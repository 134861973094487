import React, { useState, useEffect } from 'react';
import { Modal } from 'antd'

//视频预览方面
const Preview = ({ title = null, url = null, ready = null }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    useEffect(() => {
        setPreviewTitle(title);
        setPreviewUrl(url);
    }, [title, url]);

    useEffect(() => {
        //把关闭与打开函数回调到外部
        if (typeof ready === 'function') {
            ready((options) => {
                const { title, url } = options || {};
                if (typeof title !== 'undefined') {
                    setPreviewTitle(title);
                }
                if (typeof url !== 'undefined') {
                    setPreviewUrl(url);
                }
                setPreviewVisible(true);
            }, () => setPreviewVisible(false));
        }
    }, [ready]);

    return <Modal
        visible={previewVisible}
        title={previewTitle || null}
        width={'80vh'}
        style={{ top: 10, height: '95vh' }}
        footer={null}
        onCancel={() => setPreviewVisible(false)}>
        {previewVisible && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '85vh', marginTop: '2vh' }}>
            <video style={{ height: '100%', maxWidth: '100%' }} src={previewUrl} controls autoPlay />
        </div>}
    </Modal>
}

export default Preview;
