import { message } from 'antd';
import httpServer from '../utils/http-server';
// =================  请求api部分 ==============
const mainApiUrl = 'system/setting';

// ==================   action =================
export const getListData = () => (dispatch) => {
    httpServer.get(mainApiUrl).then((res) => {
        if (res.errcode === 0 && res.data) {
            dispatch({
                type: actionType.ADD,
                list: res.data
            });
        } else {
            message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
            console.error('获取数据错误');
        }
    });
};

// 更新数据
export const updateData = (configs) => {
    return new Promise((resolve, reject) => {
        if (typeof configs === 'object' && configs !== null) {
            return httpServer.post(mainApiUrl, { configs }).then((res) => {
                if (res.errcode === 0) {
                    message.success('修改数据成功');
                    resolve();
                } else {
                    message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
                    console.error('获取数据错误', res);
                    reject();
                }
            });
        }
        message.error('系统错误，请刷新重试！！！');
        console.error('系统错误', list);
        reject();
    });
};

//  ==================   reducers  ==============
const initState = {
    list: []
};
const actionType = {
    ADD: 'ADD'
};

export default (state = initState, action) => {
    switch (action.type) {
        case actionType.ADD:
            return { ...state, list: [...action.list] };
        default:
            return state;
    }
};
