import { message } from 'antd';
import httpServer from '../utils/http-server';
// =================  请求api部分 ==============
const mainApiUrl = '/video';

// ==================   action =================

export const getListData = function (params) {
    return new Promise((resolve) => {
        httpServer
            .post('/ad-brand/list', params)
            .then((res) => {
                resolve({
                    data: res.data.data,
                    // success 请返回 true，
                    // 不然 table 会停止解析数据，即使有数据
                    success: true,
                    // 不传会使用 data 的长度，如果是分页一定要传
                    total: res.data.total
                });
            })
            .catch((err) => {
                resolve({
                    data: [],
                    // success 请返回 true，
                    // 不然 table 会停止解析数据，即使有数据
                    success: false,
                    // 不传会使用 data 的长度，如果是分页一定要传
                    total: 0
                });
            });
    });
};

// 更新数据
export const updateData = (data) => (dispatch) => {
    httpServer.post('/ad-brand/update', { ...data }).then((res) => {
        if (res.errcode === 0) {
            dispatch({
                type: actionType.MODIFY,
                data: data
            });
            message.success('修改数据成功');
        } else {
            message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
            console.error('获取数据错误');
        }
    });
};
// 添加数据
// export const addData = (data) => (dispatch) => {
//
//     httpServer.put('/ad-brand/add', data).then((res) => {
//         if (res.errcode === 0) {
//             dispatch({
//                 type: actionType.ADD,
//                 data: res.data
//             });
//             message.success('添加数据成功');
//         } else {
//             message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
//             console.error('获取数据错误');
//         }
//     });
// };

export const addData = (data) => {
    return new Promise(((resolve, reject) => {
        httpServer.put('/ad-brand/add', data).then((res) => {
            if (res.errcode === 0) {
                // message.success('添加数据成功');
                resolve(res);
            } else {
                // message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
                // console.error('获取数据错误');
                reject(res.msg);
            }
        });
    }))
};
// 删除数据
export const deleteData = (ids) => {
    return new Promise((resolve, reject) => {
        httpServer.delete('/ad-brand/del', ids).then((res) => {
            if (res.errcode === 0) {
                // message.success('删除数据成功');
                resolve(res);
            } else {
                // message.error(res.errcode === 1 ? res.msg : '系统错误，请稍后重试');
                // console.error('获取数据错误');
                reject(res.msg);
            }
        });
    })
};

//  ==================   reducers  ==============

const initState = {
    list: [],
    page: 1,
    total: 0
};
const actionType = {
    ADD: 'ADD',
    DELETE: 'DELETE',
    MODIFY: 'MODIFY',
    LOAD: 'LOAD'
};

export default (state = initState, action) => {
    switch (action.type) {
        case actionType.LOAD:
            return { ...state, list: [...action.list] };
        case actionType.ADD:
            return { ...state, list: [...state.list, action.data] };
        case actionType.MODIFY:
            return {
                ...state,
                list: state.list.map((it) => (it.videoId === action.data.id ? { ...it, ...action.data } : it))
            };
        case actionType.DELETE:
            return {
                ...state,
                list: state.list.filter((it) => it.videoId !== action.id)
            };
        default:
            return state;
    }
};
