import React, { useEffect, useState, useRef } from 'react';
import { Button, Popconfirm, message, Modal, Form, Image, Input, Upload,Typography } from 'antd';

import { withRouter } from 'react-router-dom';
import { addURLHost, fallbackUrl } from '../../services/file';
import { connect } from 'react-redux';
import { getListData, addData, updateData, deleteData } from '../../store/advert';
import { date2str } from '../../utils/time.utils';
import PreviewVideo from '../../components/common/PreviewVideo';
import ProTable from '@ant-design/pro-table';
import { PlusOutlined } from '@ant-design/icons';
import { getBase64, getPicBase64 } from '../../utils/file.utils';
import { apiHost } from '../../config/system.config';

const { Paragraph } = Typography;

function Index(props) {
    const columns = [
        {
            title: '平台名称',
            dataIndex: 'name',
            align: 'center',
            fixed: 'left',
            editable: false
        },
        {
            title: '平台简称',
            dataIndex: 'nameShort',
            align: 'center',
            fixed: 'left',
            editable: false
        },
        {
            title: '链接',
            dataIndex: 'link',
            fixed: 'left',
            width: 350,
            align: 'center',
            editable: false,
            search: false,
            render: (link) => {
                return (
                    <span style={{ cursor: 'pointer', paddingLeft: '10px',textAlign:'left'}}>
                        <Paragraph copyable>{link}</Paragraph>
                    </span>
                );
            }
        },
        {
            title: '添加时间',
            dataIndex: 'createTime',
            align: 'center',
            editable: false,
            search: false,
            width: 120,
            sorter: true,
            render: (val) => date2str(val)
        },
        {
            title: '当天点击量',
            dataIndex: 'dayHitNum',
            align: 'center',
            width: 100,
            editable: false,
            // sorter: true,
            search: false
        },
        {
            title: '累计点击量',
            dataIndex: 'totalHitNum',
            align: 'center',
            width: 100,
            ellipsis: true,
            editable: false,
            // sorter: true,
            search: false
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            render: (text, record, _, action) => (
                <React.Fragment>
                    <Popconfirm
                        title="是否删除？"
                        onConfirm={() => {
                            deleteData([record.id]).then((res)=>{
                                if(res.code === 200){
                                    message.success("删除数据成功");
                                    actionRef.current.reload();
                                }else{
                                    message.warn(res.msg)
                                }
                            }).catch((msg)=>{
                                message.error(msg);
                            })

                        }}
                    >
                        <a style={{color: '#999' }}>删除</a>
                    </Popconfirm>
                </React.Fragment>
            )
        }
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const actionRef = useRef();
    const uploadPath = apiHost + '/file/img/upload';
    const uploadParam = { path: 'company' };
    //创建表单
    const [form] = Form.useForm();

    //编辑框状态
    const [editModeStatus, setEditModeStatus] = useState(false);
    //是否添加模式
    const [isAddModel, setIsAddModel] = useState(true);
    //表单内容
    const d = document;
    const [formContent, setFormContent] = useState({});
    const [licenseFileList, setLicenseFileList] = useState([]);
    const [authFileList, setAuthFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState({});
    const [newLink, setNewLink] = useState('');

    //过滤数据处理
    const handlefilter = (filters) => {
        const myfilter = filters;

        const realFliter = filters;
        Object.keys(realFliter).forEach((key) => {
            if (realFliter[key] == null || realFliter[key].length == 0) {
                delete realFliter[key];
            }
        });
        return realFliter;
    };
    // 排序
    const handerSorter = (sorter) => {
        Object.keys(sorter).forEach((key) => {
            if (key === 'id') {
                sorter['orderId'] = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
                delete sorter['id'];
            } else {
                sorter[key] = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
            }
        });
        return sorter;
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getPicBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const restformData = () => {
        form.resetFields();
        setAuthFileList([]);
        setLicenseFileList([]);
    };
    const createLink = (event) => {
        let link = location.origin+'/other/spread01-'+event.target.value+'.html';
        setNewLink(link);
    };
    return (
        <React.Fragment>
            <ProTable
                actionRef={actionRef}
                headerTitle="投放管理统计"
                columns={columns}
                // dataSource={agentdata}
                scroll={{ x: 1000 }}
                rowKey="id"
                pagination={{
                    pageSize: 10
                }}
                hideSelectAll={true}
                editable={{
                    type: 'single',
                    onSave: (id, row) => {
                        props.dispatch(updateData(id, { descript: row.descript }));
                    },
                    actionRender: (row, config, dom) => [dom.save, dom.cancel]
                }}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false
                }}
                toolbar={{
                    actions: [
                        <Button
                            type="primary"
                            onClick={() => {
                                restformData();
                                setIsAddModel(true);
                                setEditModeStatus(true);
                            }}
                        >
                            添加投放平台
                        </Button>
                    ]
                }}
                request={async (params, sorter, filter) => {
                    const mysort = handerSorter(sorter);
                    const myfilter = handlefilter(filter);
                    return await getListData({ ...params, ...mysort, ...myfilter });
                }}
            ></ProTable>
            {/* 编辑框 */}
            <Modal
                title={'添加投放平台'}
                visible={editModeStatus}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            if (values) {
                                if (typeof formContent === 'object' && formContent !== null) {
                                    let link = newLink.replace(location.origin+'/','');
                                    const data = { ...formContent, ...values,link };
                                    addData({...data}).then((res)=>{
                                        if(res.code === 200){
                                            message.success("添加投放平台成功")
                                            setEditModeStatus(false);
                                            setNewLink('');
                                            actionRef.current.reload();
                                        }else {
                                            message.warn(res.msg);
                                        }
                                    }).catch((msg)=>{
                                        message.error(msg);
                                    })

                                } else {
                                    message.info('系统错误').then(() => {
                                        console.error('formContent必须是一个对象', formContent);
                                    });
                                }
                            } else {
                                message.error('系统错误，请刷新重试！！！');
                            }
                        })
                        .catch((err) => {
                            const { errorFields } = err || {};
                            if (Array.isArray(errorFields) && errorFields.length > 0) {
                                message.error(errorFields.map((it) => it.errors.join('，')).join('，'));
                            } else {
                                console.error('无法识别的错误=>', err);
                            }
                        });
                }}
                onCancel={() => {
                    setEditModeStatus(false)
                    setNewLink('')
                }}
            >
                {editModeStatus && <Form
                    labelCol={{ span: 4 }}
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    initialValues={{ is_show: 1, sort: 9999 }}
                >
                    {/*<Form.Item name="id"></Form.Item>*/}
                    <Form.Item label="平台名称" name="name" rules={[{ required: true, message: '请输入平台名称!' },{max:20,message:'长度不能超过20'}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="平台简称" name="nameShort" rules={[{ required: true, message: '请输入平台简称!' },
                        { pattern: new RegExp(/^[0-9a-zA-Z_]{1,}$/, "g") , message: '名称只允许包含数字、字母和下划线'},
                        {max:20,message:'长度不能超过20'}]}>
                        <Input onChange={createLink} />
                    </Form.Item>
                    {newLink &&
                    (<div>
                        <span style={{'paddingRight':'7px','paddingLeft':'40px'}}>链接：</span><Typography.Paragraph copyable={!!newLink} style={{'display':'inline-block'}}>{newLink}</Typography.Paragraph>
                    </div>)
                    }
                </Form>}
            </Modal>
            <Modal
                visible={previewVisible}
                onCancel={() => {
                    debugger
                    setPreviewVisible(false);
                    setNewLink('');
                }}
                onOk={() => {
                    setPreviewVisible(false);
                    setNewLink('');
                }}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </React.Fragment>

    );
}

export default connect((state) => state.advert)(withRouter(Index));
