import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Avatar } from 'antd';
import {
    UsergroupDeleteOutlined,
    VideoCameraOutlined,
    DownOutlined,
    GlobalOutlined,
    DeploymentUnitOutlined,
    CrownOutlined
} from '@ant-design/icons';
import logo from '../../../resources/logo.png';
import './Index.css';
import { withRouter } from 'react-router-dom';
import {
    userRoutes,
    videoRoutes,
    globalRoutes,
    legoinRoutes,
    activeCodeRoutes,
    agentRoutes,
    advertRoutes,
} from '../../../route'
import { removeRouteArgsDefine, removeRouteArgs } from '../../../utils/route.utils';
import { logout } from '../../../services/user';
import { getUser } from '../../../services/auth';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

// 用户管理
const userMenuList = userRoutes
    .map((it) => (it && it.isShow ? removeRouteArgsDefine(it) : null))
    .filter((it) => Boolean(it));
// 视频管理
const videoMenuList = videoRoutes
    .map((it) => (it && it.isShow ? removeRouteArgsDefine(it) : null))
    .filter((it) => Boolean(it));
// 军团联盟管理
const legoinMenuList = legoinRoutes
    .map((it) => (it && it.isShow ? removeRouteArgsDefine(it) : null))
    .filter((it) => Boolean(it));
// 激活码管理
const activeCodeMenuList = activeCodeRoutes
    .map((it) => (it && it.isShow ? removeRouteArgsDefine(it) : null))
    .filter((it) => Boolean(it));
// 代理商管理
const agentMenuList = agentRoutes
    .map((it) => (it && it.isShow ? removeRouteArgsDefine(it) : null))
    .filter((it) => Boolean(it));
// 广告平台管理
const advertMenuList = advertRoutes
    .map((it) => (it && it.isShow ? removeRouteArgsDefine(it) : null))
    .filter((it) => Boolean(it));

// 全配置列表
const globalMenuList = globalRoutes
    .map((it) => (it && it.isShow ? removeRouteArgsDefine(it) : null))
    .filter((it) => Boolean(it));

function Index(props) {
    const [loginUser] = useState(getUser());
    // 路由跳转
    const routeChange = (e) => {
        let path = e;
        if (e && e.key) {
            path = e.key;
        }
        props.history.push(path);
    };

    return (
        <Layout>
            <Sider width={200} className="site-layout-background">
                <Header className="header" style={{ padding: 0, overflow: 'hidden' }}>
                    <div className="logo" onClick={() => props.history.push('/admin')}>
                        <img src={logo} alt="股播" />
                    </div>
                </Header>
                <Menu
                    mode="inline"
                    className="menu-container"
                    selectedKeys={[removeRouteArgs([...videoRoutes, ...userRoutes], props.history.location.pathname)]}
                    theme="dark"
                >
                    {/* <SubMenu key="user" icon={<UsergroupDeleteOutlined />} title="用户管理">
                        {userMenuList.map((it) => (
                            <Menu.Item key={it.path} onClick={routeChange}>
                                {it.title}
                            </Menu.Item>
                        ))}
                    </SubMenu> */}
                    <SubMenu key="video" icon={<VideoCameraOutlined />} title="视频管理">
                        {videoMenuList.map((it) => (
                            <Menu.Item key={it.path} onClick={routeChange}>
                                {it.title}
                            </Menu.Item>
                        ))}
                    </SubMenu>
                    <SubMenu key="legoin" icon={<DeploymentUnitOutlined />} title="军团管理">
                        {legoinMenuList.map((it) => (
                            <Menu.Item key={it.path} onClick={routeChange}>
                                {it.title}
                            </Menu.Item>
                        ))}
                    </SubMenu>
                    <SubMenu key="activeCode" icon={<CrownOutlined />} title="激活码管理">
                        {activeCodeMenuList.map((it) => (
                            <Menu.Item key={it.path} onClick={routeChange}>
                                {it.title}
                            </Menu.Item>
                        ))}
                    </SubMenu>
                    <SubMenu key="agent" icon={<VideoCameraOutlined />} title="代理商管理">
                        {agentMenuList.map((it) => (
                            <Menu.Item key={it.path} onClick={routeChange}>
                                {it.title}
                            </Menu.Item>
                        ))}
                    </SubMenu>
                    <SubMenu key="advert" icon={<VideoCameraOutlined />} title="广告平台管理">
                        {advertMenuList.map((it) => (
                            <Menu.Item key={it.path} onClick={routeChange}>
                                {it.title}
                            </Menu.Item>
                        ))}
                    </SubMenu>

                    <SubMenu key="system" icon={<GlobalOutlined />} title="全局配置">
                        {globalMenuList.map((it) => (
                            <Menu.Item key={it.path} icon={it.icon} onClick={routeChange}>
                                {it.title}
                            </Menu.Item>
                        ))}
                    </SubMenu>
                </Menu>
            </Sider>
            <Layout>
                <Header className="header" style={{ background: '#fff' }}>
                    <div style={{ float: 'right' }}>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item onClick={() => logout().then(() => props.history.push('/login'))}>
                                        退出登录
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <div>
                                <Avatar style={{ transform: 'scale(0.75)' }}>U</Avatar>
                                &nbsp;&nbsp;
                                {loginUser ? loginUser.name : '超级管理员'}
                                &nbsp;&nbsp;
                                <DownOutlined />
                            </div>
                        </Dropdown>
                    </div>
                </Header>
                <Layout style={{ padding: '16px', minHeight: 'calc(100% - 64px)' }}>
                    <Content
                        style={{
                            margin: 0
                        }}
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default withRouter(Index);
