import React, { useEffect, useState, useRef } from 'react';
import { Button, Popconfirm, message, Modal, Form, Image, Input, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import { addURLHost, fallbackUrl } from '../../services/file';
import { connect } from 'react-redux';
import { getListData, addData, updateData, deleteData } from '../../store/agent';
import { date2str } from '../../utils/time.utils';
import PreviewVideo from '../../components/common/PreviewVideo';
import ProTable from '@ant-design/pro-table';
import { PlusOutlined } from '@ant-design/icons';
import { getBase64, getPicBase64 } from '../../utils/file.utils';
import { apiHost, prefix} from '../../config/system.config';

function Index(props) {
    const columns = [
        {
            title: '代理id',
            width: 45,
            dataIndex: 'id',
            align: 'center',
            fixed: 'left',
            editable: false
        },
        {
            title: '公司名称',
            width: 45,
            dataIndex: 'name',
            align: 'center',
            fixed: 'left',
            editable: false
        },
        {
            title: '营业执照',
            dataIndex: 'licenseUrl',
            align: 'center',
            width: 80,
            editable: false,
            search: false,
            render: (val, row) => <Image height={120} src={addURLHost(val)} preview={true} fallback={fallbackUrl} />
        },
        {
            title: '授权书',
            dataIndex: 'authUrl',
            align: 'center',
            width: 80,
            editable: false,
            search: false,
            render: (val, row) => <Image height={120} src={addURLHost(val)} preview={true} fallback={fallbackUrl} />
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            align: 'center',
            width: 50,
            ellipsis: true,
            editable: false
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            editable: false,
            search: false,
            width: 80,
            sorter: true,
            render: (val) => date2str(val)
        },

        {
            title: '操作',
            width: 80,
            align: 'center',
            fixed: 'right',
            valueType: 'option',
            render: (text, record, _, action) => (
                <React.Fragment>
                    <a
                        key="editable"
                        onClick={() => {
                            console.log('编辑代理商=>');
                            restformData();
                            form.setFieldsValue(record);
                            setAuthFileList([
                                {
                                    status: 'done',
                                    url: addURLHost(record.authUrl)
                                }
                            ]);
                            setLicenseFileList([
                                {
                                    status: 'done',
                                    url: addURLHost(record.licenseUrl)
                                }
                            ]);
                            setIsAddModel(false);
                            setEditModeStatus(true);
                            actionRef.current.reload();
                        }}
                    >
                        编辑
                    </a>
                    <Popconfirm
                        title="是否删除？"
                        onConfirm={() => {
                            props.dispatch(deleteData([record.id]));
                            actionRef.current.reload();
                        }}
                    >
                        <a style={{ marginLeft: '1em', color: '#999' }}>删除</a>
                    </Popconfirm>
                </React.Fragment>
            )
        }
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const actionRef = useRef();
    const uploadPath = apiHost + prefix + '/file/img/upload';
    const uploadParam = { path: 'company' };
    //创建表单
    const [form] = Form.useForm();

    //编辑框状态
    const [editModeStatus, setEditModeStatus] = useState(false);
    //是否添加模式
    const [isAddModel, setIsAddModel] = useState(true);
    //表单内容
    const [formContent, setFormContent] = useState({});
    const [licenseFileList, setLicenseFileList] = useState([]);
    const [authFileList, setAuthFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState({});

    //过滤数据处理
    const handlefilter = (filters) => {
        const myfilter = filters;

        const realFliter = filters;
        Object.keys(realFliter).forEach((key) => {
            if (realFliter[key] == null || realFliter[key].length == 0) {
                delete realFliter[key];
            }
        });
        return realFliter;
    };
    // 排序
    const handerSorter = (sorter) => {
        console.log(sorter);
        Object.keys(sorter).forEach((key) => {
            if (key === 'id') {
                sorter['orderId'] = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
                delete sorter['id'];
            } else {
                sorter[key] = sorter[key] === 'ascend' ? 'ASC' : 'DESC';
            }
        });
        return sorter;
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getPicBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const restformData = () => {
        form.resetFields();
        setAuthFileList([]);
        setLicenseFileList([]);
    };
    return (
        <React.Fragment>
            <ProTable
                actionRef={actionRef}
                headerTitle="授权代理商管理"
                columns={columns}
                // dataSource={agentdata}
                scroll={{ x: 1000 }}
                rowKey="id"
                pagination={{
                    pageSize: 5
                }}
                editable={{
                    type: 'single',
                    onSave: (id, row) => {
                        props.dispatch(updateData(id, { descript: row.descript }));
                    },
                    actionRender: (row, config, dom) => [dom.save, dom.cancel]
                }}
                rowSelection={{
                    columnWidth: 30,
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys)
                }}
                options={{
                    density: false,
                    fullScreen: false,
                    reload: false
                }}
                toolbar={{
                    actions: [
                        <Button
                            type="primary"
                            onClick={() => {
                                console.log('新增代理商=>');
                                restformData();
                                setIsAddModel(true);
                                setEditModeStatus(true);
                            }}
                        >
                            新增代理商
                        </Button>,
                        <Popconfirm
                            title="是否删除选中的行？"
                            onConfirm={() => {
                                props.dispatch(deleteData(selectedRowKeys));
                                actionRef.current.reload();
                            }}
                        >
                            <Button>删除</Button>
                        </Popconfirm>
                    ]
                }}
                request={async (params, sorter, filter) => {
                    const mysort = handerSorter(sorter);
                    const myfilter = handlefilter(filter);
                    return await getListData({ ...params, ...mysort, ...myfilter });
                }}
            ></ProTable>
            {/* 编辑框 */}
            <Modal
                title={(isAddModel ? '新增' : '编辑') + '代理商'}
                visible={editModeStatus}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            if (values) {
                                if (typeof formContent === 'object' && formContent !== null) {
                                    const data = { ...formContent, ...values };
                                    if (data.id) {
                                        props.dispatch(
                                            updateData({
                                                ...data,
                                                authUrl:
                                                    data.authImg == null
                                                        ? null
                                                        : data.authImg.file.response.data[0].uri,
                                                licenseUrl:
                                                    data.licenseImg == null
                                                        ? null
                                                        : data.licenseImg.file.response.data[0].uri
                                            })
                                        );
                                    } else {
                                        props.dispatch(
                                            addData({
                                                ...data,
                                                authUrl: data.authImg.file.response.data[0].uri || '',
                                                licenseUrl: data.licenseImg.file.response.data[0].uri || ''
                                            })
                                        );
                                    }
                                    actionRef.current.reload();
                                    setEditModeStatus(false);
                                } else {
                                    message.info('系统错误').then(() => {
                                        console.error('formContent必须是一个对象', formContent);
                                    });
                                }
                            } else {
                                message.error('系统错误，请刷新重试！！！');
                            }
                        })
                        .catch((err) => {
                            const { errorFields } = err || {};
                            if (Array.isArray(errorFields) && errorFields.length > 0) {
                                message.error(errorFields.map((it) => it.errors.join('，')).join('，'));
                            } else {
                                console.error('无法识别的错误=>', err);
                            }
                        });
                }}
                onCancel={() => setEditModeStatus(false)}
            >
                <Form
                    labelCol={{ span: 4 }}
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    initialValues={{ is_show: 1, sort: 9999 }}
                >
                    <Form.Item name="id"></Form.Item>
                    <Form.Item label="公司名称" name="name" rules={[{ required: true, message: '请输入公司名称!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="联系电话" name="phone" rules={[{ required: true, message: '请输入联系电话!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="营业执照" name="licenseImg">
                        <Upload
                            action={uploadPath}
                            data={uploadParam}
                            listType="picture-card"
                            fileList={licenseFileList}
                            onPreview={handlePreview}
                            multiple={false}
                            maxCount={1}
                            onChange={({ fileList }) => {
                                setLicenseFileList(fileList);
                            }}
                        >
                            {licenseFileList.length >= 1 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                    <Form.Item label="授权书" name="authImg">
                        <Upload
                            action={uploadPath}
                            data={uploadParam}
                            listType="picture-card"
                            fileList={authFileList}
                            onPreview={handlePreview}
                            multiple={false}
                            maxCount={1}
                            onChange={({ fileList }) => {
                                setAuthFileList(fileList);
                            }}
                        >
                            {authFileList.length >= 1 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={previewVisible}
                onCancel={() => {
                    setPreviewVisible(false);
                }}
                onOk={() => {
                    setPreviewVisible(false);
                }}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </React.Fragment>
    );
}

export default connect((state) => state.agent)(withRouter(Index));
