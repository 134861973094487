// 前缀（前缀必须以/api开头）
const prefix = '/api/admin';

// 前缀正则匹配
const prefixRe = new RegExp(`^${prefix}(.*?)$`);

// 开发服务器
const devHost = 'https://mp.ts20.cn';

// 导出变量
module.exports = {
    devHost,
    prefix,
    // 代理（路径重写）
    proxy: function (req) {
        if (prefixRe.test(req)) {
            const requestUrl = req.replace(prefixRe, `${prefix}$1`);
            console.log(`代理URL=>${devHost}${requestUrl}`);
            return requestUrl;
        } else {
            console.error(`请求的地址不符合前缀规则，URL=>${req}，prefix=${prefix}`);
            return req;
        }
    }
};
