/*
 * @Description: 时间工具
 * @Author: wkj
 * @Date: 2020-07-07 11:42:45
 * @,@LastEditTime: ,: 2021-01-23 17:03:44
 * @,@LastEditors: ,: wkj
 */
//时间转字符串
export const date2str = (date, format = 'YY-MM-DD h:m:s') => {
    const dt = typeof date;
    if (dt === 'object' && date !== null) {
        if (isNaN(date.getFullYear())) {
            date = new Date(0);
        }
    } else if (dt === 'string' || dt === 'number') {
        if (dt === 'string' && dt.length === 8) {
            date = date.replace(/^(\d\d)(\d\d)(\d\d)$/, '$1-$2-$3');
        }
        date = new Date(date);
    } else {
        date = new Date(0);
    }
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();
    let get2LenStr = (val) => isNaN(val) ? '-' : (val >= 10 ? val : '0' + val);
    let timeStr = format
        .replace(/YY/gi, get2LenStr(year))
        .replace(/MM/g, get2LenStr(month))
        .replace(/DD/g, get2LenStr(day))
        .replace(/h/g, get2LenStr(h))
        .replace(/m/g, get2LenStr(m))
        .replace(/s/g, get2LenStr(s));
    return timeStr;
};

//是否时间戳
const isTimestamp = (timestamp) => {
    return /^\d+$/.test(timestamp);
};

//获取当前时间
export const getCurrentTimeStr = (format = 'YY-MM-DD h:m') => {
    return date2str(new Date(), format);
};

//获取当前时间戳
export const getCurrentStamp = () => {
    return new Date().getTime();
};

//时间戳转字符串
export const timestamp2str = (timestamp, format) => {
    return date2str(new Date(timestamp), format);
};

//时间戳转日期
export const timestamp2dateStr = (timestamp) => {
    return isTimestamp(timestamp) ? date2str(new Date(timestamp), 'YY-MM-DD') : timestamp;
};

//时间戳转日期时间
export const timestamp2datetimeStr = (timestamp) => {
    return isTimestamp(timestamp) ? date2str(new Date(timestamp), 'YY-MM-DD h:m:s') : timestamp;
};

//时间字符串转日期
export const timeStr2dateStr = (timeStr) => {
    return date2str(dateStr2Timestamp(timeStr), 'YY-MM-DD');
};

//字符串转时间戳
export const dateStr2Timestamp = (timeStr) => {
    if (typeof timeStr === 'string' && timeStr !== '') {
        let date = timeStr.substring(0, 19);
        return new Date(date.replace(/-/g, '/')).getTime();
    } else {
        if (isTimestamp(timeStr)) {
            return timeStr;
        } else {
            console.warn('时间字符串不合法：', timeStr);
            return 0;
        }
    }
};

//时间字符串转日期
export const timeStr2datetimeStr = (time2str) => {
    return date2str(dateStr2Timestamp(time2str), 'YY-MM-DD h:m:s');
};

//字符串或者时间戳转时间对象(个性化转成时间)
export const personalize2DateStr = (time2str, format = 'YY年MM月DD日') => {
    let timestamp = time2str;
    if (!isTimestamp(time2str)) {
        timestamp = dateStr2Timestamp(time2str);
    }
    return date2str(new Date(timestamp), format);
};

//距离现在秒数
export const toCurrentSeconds = (timeStr) => {
    return (getCurrentStamp() - dateStr2Timestamp(timestamp2datetimeStr(timeStr))) / 1000;
};
