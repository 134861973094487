/*
 * @Description: 服务器请求(使用axios提供)
 * @Author: wkj
 * @Date: 2020-03-04 19:38:00
 * @LastEditTime: 2022-04-22 17:28:42
 * @LastEditors: wkj
 * @LastEditTip:
 */
import axios from 'axios';
import { mergeUrl } from './url.utils';
import { apiHost, useProxy, prefix } from '../config/system.config';
import { message } from 'antd';
import { setUser } from '../services/auth';
// import Qs from "qs";

//构造axios实例
export const axiosInstance = axios.create({
    baseURL: (useProxy ? '' : apiHost) + prefix,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    // 使用表单提交
    // transformRequest: [function (data) {
    //     data = Qs.stringify(data)
    //     return data
    // }],
    withCredentials: true
});

const httpServer = {
    ajax(url, sendMethod, data) {
        var axiosOptions = {};
        axiosOptions.method = sendMethod;
        if (sendMethod === 'GET') {
            url = mergeUrl(url, data);
        } else {
            axiosOptions.data = data;
        }
        return new Promise((resolve, reject) => {
            axiosInstance
                .request({
                    url: url,
                    ...axiosOptions
                })
                .then((response) => {
                    const res = response.data;
                    if (typeof res === 'object' && res !== null) {
                        if (res.code === 308) {
                            message.error('系统已经退出登录，请重新登录').then(() => {
                                setUser(null);
                                window.location.href = '/';
                            });
                        } else {
                            resolve(res);
                        }
                    } else {
                        message.error('系统错误，请联系管理员');
                        reject({
                            err: '后台接口不规范',
                            res
                        });
                    }
                })
                .catch((err) => {
                    message.error('网络错误，请重试！！！');
                    reject(err);
                });
        });
    },
    post(url, data) {
        return this.ajax(url, 'POST', data);
    },
    get(url, data) {
        return this.ajax(url, 'GET', data);
    },
    put(url, data) {
        return this.ajax(url, 'PUT', data);
    },
    delete(url, data) {
        return this.ajax(url, 'DELETE', data);
    },
    upload(url, data) {
        let formData = new FormData();
        for (var key in data) {
            formData.append(key, data[key]);
        }
        return this.ajax(url, 'POST', formData);
    }
};

export { apiHost };

export default httpServer;
