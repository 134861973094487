// 是否字符串
export const isStr = function (str) {
    return typeof str === 'string' && str != '';
};

// 是否字符串
export const isArr = function (arr) {
    return Array.isArray(arr);
};

// 是否数字
export const isNum = function (num) {
    return typeof num === 'number' && !isNaN(num);
};

// 是否对象
export const isObj = function (obj) {
    return typeof obj === 'object' && obj != null;
};
